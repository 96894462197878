import { createGtm } from "@gtm-support/vue-gtm";
export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.use(createGtm({
        id: 'GTM-P5MVR7K',
        debug: true,
        loadScript: true,
        enabled:true,
        vueRouter: useRouter(),
        vueRouterAdditionalEventData: (dat) => ({
         someComputedProperty: ()=>{console.log(dat)},
         }),
    }))
})