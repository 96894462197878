import { processSlotOutlet } from '@vue/compiler-core'

// let loaded = false;

const state = reactive({
  loaded: false,
  loading:false,
  config: null,
  links: [],
  home: {},
  editing: false,
  app: { debug: false },
  categories: [],
  languages: {},
  content_version: "draft",
  started: false,
})
// async function initialConfigLoad() {
//   const runtimeConfig = useRuntimeConfig()
//   const storyblokApi = useStoryblokApi()
//   const { locale, locales } = useI18n()

//   state.editing = process.client ? (window && window.location.href.indexOf('_storyblok') > -1) || false : false
//   state.content_version = runtimeConfig.public.content_version

  
// }
// await initialConfigLoad()
function handleMenuItem(menuitem: any, links: any, locale: string) {
  try {
    // console.log(menuitem.link.id || menuitem )
    if (menuitem.link?.id != '') {
      // console.log("here!")
      menuitem.link.real_story = links.find((l: any) => {
        return menuitem.link.story?.uuid == l?.uuid
      })
     
      menuitem.link.real_story.full_slug = menuitem.link.story.full_slug
        .replace('pages', '')
        .replace(/\/$/, '')
      if (locale != 'en') {
        menuitem.link.real_story.full_slug = menuitem.link.real_story.full_slug.replace(
          locale + '/',
          '',
        )
      }
    } else {
      // console.log("faulty", menuitem)
    }

    if (menuitem.submenu.length > 0) {
      menuitem.submenu.forEach((sub: any) => {
        handleMenuItem(sub, links, locale)
      })
    }
  } catch (error) {
    console.error(error)
  }
}
// async function asyncConfig(refresh: boolean, storyblokApi: any, locale: any) {

//   // const { data } = await useAsyncData('main', () => storyblokApi.get('cdn/stories/main', {
//   //   version: state.content_version as 'draft' | 'published',
//   //   resolve_links: 'url',
//   //   language: locale.value == 'en' ? '' : locale.value,
//   //   resolve_relations: 'PageLink.story',
//   // }))
//   if (!state.loaded || refresh) {
//     console.log("fetchConfig - using : ", state.content_version, " , refresh : ", refresh)
//     const [main_story, languages, languages_data, req, categories_data] = await Promise.all([
//       storyblokApi.get('cdn/stories/main', {
//         version: state.content_version as 'draft' | 'published',
//         resolve_links: 'url',
//         language: locale.value == 'en' ? '' : locale.value,
//         resolve_relations: 'PageLink.story',
//       }),
//       storyblokApi.get('cdn/spaces/me', { version: state.content_version as 'draft' | 'published' }),
//       storyblokApi.get('cdn/datasources', { version: state.content_version as 'draft' | 'published' }),
//       storyblokApi.get('cdn/stories/pages/home', {
//         version: state.content_version as 'draft' | 'published',
//         resolve_links: 'url',
//         language: locale.value == 'en' ? '' : locale.value,
//         resolve_relations: 'PageLink.story',
//       }),
//       storyblokApi.get('cdn/stories', {
//         language: locale.value == 'en' ? '' : locale.value,
//         by_slugs: 'categories/*',
//         version: state.content_version as 'draft' | 'published',
//       })
//     ])

//     // console.log(languages_data, req, categories_data)
//     // console.log(data.story.content.global_assets)

//     main_story.data.story.content.header_menu.forEach((mitem: any) => {
//       handleMenuItem(mitem, main_story.data.links, locale.value)
//     })
//     state.categories = categories_data.data.stories
//     state.home = req.data.story

//     state.loaded = true
//     state.config = main_story.data.story.content
//     state.languages = {
//       space: languages.data.space,
//       sources: languages_data.data.datasources.find(
//         (ds: any) => ds.name == 'Languages',
//       ),
//     }
//   }
// }
// function getRealStory(id: any, links: any) {
//     console.log(id,links)
//     return {}
// }
// useAsyncData( () => {
//   return new Promise(async (resolve) => {
//       resolve(true)
//   })
// })
export function useConfig() {
  // console.log(config)
  const runtimeConfig = useRuntimeConfig()
  const storyblokApi = useStoryblokApi()
  const { locale, locales } = useI18n()

  state.editing = process.client ? (window && window.location.href.indexOf('_storyblok') > -1 ) || false : false
  state.content_version = runtimeConfig.public.content_version
  // console.log(runtimeConfig.public.content_version)
  // const { data } = await useAsyncData('config',async () => {
  //   return {config:{}}
  // })
  // const { data } =  useAsyncData('settings01',
  //   () => storyblokApi.get('cdn/stories/main', {
  //       version: state.content_version as 'draft' | 'published',
  //       resolve_links: 'url',
  //       language: locale.value == 'en' ? '' : locale.value,
  //       resolve_relations: 'PageLink.story',
  //     })
     
  // )
  // console.log("async data",data)
  // const { _settings } = await useAsyncData('settings', () => asyncConfig())
  function handleInternalLink(slug: string, ignore_locale: boolean) {
    // console.log(slug)
    slug = slug.replace('pages', '').replace(/\/$/, '')

    if (locale.value != 'en' && !ignore_locale) {
      slug = slug.replace(locale.value + '/', '')
    }

    slug = slug.replace('//', '/')
    // console.log(slug)
    return slug
  }
  function getRealStory(_slug: string) {
    console.log(state.links)
    var story: any = state.links.find((p: any) => p.slug == _slug)
    console.log(_slug,story)
    // if(story)story.slug = handleInternalLink(story.slug)
    return story
  }
  function getHome() {
    // var story: any = state.links.find((p: any) => //console.log(p))
    // // if(story)story.slug = handleInternalLink(story.slug)
    return state.home
  }
  function handleExternalLink(link: string) {
    return link.replace('[locale]', locale.value)
  }
  function getCategoriesByID(uuids: any) {
    return state.categories.filter((c: any) => uuids.includes(c.uuid))
  }
  async function getHeaderMenu() {

      
      const main = await storyblokApi.get('cdn/stories/main', {
        version: state.content_version as 'draft' | 'published',
        resolve_links: 'url',
        language: locale.value == 'en' ? '' : locale.value,
        resolve_relations: 'PageLink.story',
      })
      // console.log(main.data.links)
      main.data.story.content.header_menu.forEach((mitem: any) => {
        handleMenuItem(mitem, main.data.links, locale.value)
      })
      state.config = main.data.story.content
      
    state.loaded = true
    return state.config;
  }
  async function loadConfig() {

      
      const main = await storyblokApi.get('cdn/stories/main', {
        version: state.content_version as 'draft' | 'published',
        resolve_links: 'url',
        language: locale.value == 'en' ? '' : locale.value,
        resolve_relations: 'PageLink.story',
      })
      // console.log(main.data.links)
      main.data.story.content.header_menu.forEach((mitem: any) => {
        handleMenuItem(mitem, main.data.links, locale.value)
      })
      
      state.config = main.data.story.content
      
    // return state.config;
  }
  async function getConfig() {
    try {
      const [main, languages, languages_data, req, categories_data] = await Promise.all([
        storyblokApi.get('cdn/stories/main', {
          version: state.content_version as 'draft' | 'published',
          resolve_links: 'url',
          language: locale.value == 'en' ? '' : locale.value,
          resolve_relations: 'PageLink.story',
        }),
        storyblokApi.get('cdn/spaces/me', { version: state.content_version as 'draft' | 'published' }),
        storyblokApi.get('cdn/datasources', { version: state.content_version as 'draft' | 'published' }),
        storyblokApi.get('cdn/stories/pages/home', {
          version: state.content_version as 'draft' | 'published',
          resolve_links: 'url',
          language: locale.value == 'en' ? '' : locale.value,
          resolve_relations: 'PageLink.story',
        }),
        storyblokApi.get('cdn/stories', {
          language: locale.value == 'en' ? '' : locale.value,
          by_slugs: 'categories/*',
          version: state.content_version as 'draft' | 'published',
        })
      ])
      // console.log("got initial data")
      main.data.story.content.header_menu.forEach((mitem: any) => {
        handleMenuItem(mitem, main.data.links, locale.value)
      })
      state.categories = categories_data.data.stories
      state.home = req.data.story


      state.config = main.data.story.content
      state.languages = {
        space: languages.data.space,
        sources: languages_data.data.datasources.find(
          (ds: any) => ds.name == 'Languages',
        ),
      }
      state.loaded = true

    } catch (error) {
      
    }

  }
  // if (refresh) {
  //   useAsyncData(()=>getConfig())
  //   // await getConfig()
  // }
  async function fetchConfig(refresh:boolean) {
    // if ((process.server && state.loaded) || refresh) {
      
      console.log("fetchConfig - using : ", state.content_version)
      const { data } = await storyblokApi.get('cdn/stories/main', {
        version: state.content_version as 'draft' | 'published' as 'draft' | 'published',
        resolve_links: 'url',
        language: locale.value == 'en' ? '' : locale.value,
        resolve_relations: 'PageLink.story',
      })
      // console.log(data.story.content.global_assets)
      data.story.content.header_menu.forEach((mitem: any) => {
        handleMenuItem(mitem, data.links, locale.value)
      })
      state.links = data.links
      // console.log(state.links)
      const languages = await storyblokApi.get('cdn/spaces/me', { version: state.content_version as 'draft' | 'published' })
      const languages_data = await storyblokApi.get('cdn/datasources', { version: state.content_version as 'draft' | 'published' })
      const req = await storyblokApi.get('cdn/stories/pages/home', {
        version: state.content_version as 'draft' | 'published',
        resolve_links: 'url',
        language: locale.value == 'en' ? '' : locale.value,
        resolve_relations: 'PageLink.story',
      })
      const categories_data = await storyblokApi.get('cdn/stories', {
        language: locale.value == 'en' ? '' : locale.value,
        by_slugs: 'categories/*',
        version: state.content_version as 'draft' | 'published',
      })
      state.categories = categories_data.data.stories
      state.home = req.data.story
      // console.log(state.home)
      state.loaded = true
      state.config = data.story.content
  
      state.languages = {
        space: languages.data.space,
        sources: languages_data.data.datasources.find(
          (ds: any) => ds.name == 'Languages',
        ),
      }
    // }
  }
  
  async function _fetchConfig(refresh:boolean) {
   
    // const { data } = await useAsyncData('main', () => storyblokApi.get('cdn/stories/main', {
    //   version: state.content_version as 'draft' | 'published',
    //   resolve_links: 'url',
    //   language: locale.value == 'en' ? '' : locale.value,
    //   resolve_relations: 'PageLink.story',
    // }))
    // if (!state.loaded || refresh) {
      console.log("fetchConfig - using : ", state.content_version," , refresh : ",refresh)
      const [main_story, languages, languages_data, req, categories_data] = await Promise.all([
        storyblokApi.get('cdn/stories/main', {
          version: state.content_version as 'draft' | 'published',
          resolve_links: 'url',
          language: locale.value == 'en' ? '' : locale.value,
          resolve_relations: 'PageLink.story',
        }),
        storyblokApi.get('cdn/spaces/me', { version: state.content_version as 'draft' | 'published' }),
        storyblokApi.get('cdn/datasources', { version: state.content_version as 'draft' | 'published' }),
        storyblokApi.get('cdn/stories/pages/home', {
          version: state.content_version as 'draft' | 'published',
          resolve_links: 'url',
          language: locale.value == 'en' ? '' : locale.value,
          resolve_relations: 'PageLink.story',
        }),
        storyblokApi.get('cdn/stories', {
          language: locale.value == 'en' ? '' : locale.value,
          by_slugs: 'categories/*',
          version: state.content_version as 'draft' | 'published',
        })
      ])
    
      // console.log(languages_data, req, categories_data)
      // console.log(data.story.content.global_assets)
  
      main_story.data.story.content.header_menu.forEach((mitem: any) => {
        handleMenuItem(mitem, main_story.data.links, locale.value)
      })
      state.categories = categories_data.data.stories
      state.home = req.data.story
  
      state.loaded = true
      state.config = main_story.data.story.content
      state.languages = {
        space: languages.data.space,
        sources: languages_data.data.datasources.find(
          (ds: any) => ds.name == 'Languages',
        ),
      }
    // }
    // state.links = data.links
    // // console.log(state.links)
    // const languages = await storyblokApi.get('cdn/spaces/me', { version: state.content_version as 'draft' | 'published' })
    // const languages_data = await storyblokApi.get('cdn/datasources', { version: state.content_version as 'draft' | 'published' })
    // const req = await storyblokApi.get('cdn/stories/pages/home', {
    //   version: state.content_version as 'draft' | 'published',
    //   resolve_links: 'url',
    //   language: locale.value == 'en' ? '' : locale.value,
    //   resolve_relations: 'PageLink.story',
    // })
    // const categories_data = await storyblokApi.get('cdn/stories', {
    //   language: locale.value == 'en' ? '' : locale.value,
    //   by_slugs: 'categories/*',
    //   version: state.content_version as 'draft' | 'published',
    // })
    // state.categories = categories_data.data.stories
    // state.home = req.data.story
    // // console.log(state.home)
    // state.loaded = true
    // state.config = data.story.content

    // state.languages = {
    //   space: languages.data.space,
    //   sources: languages_data.data.datasources.find(
    //     (ds: any) => ds.name == 'Languages',
    //   ),
    // }
  }
  function getUrl(_url: String) {
    if (_url.indexOf('/') != 0) _url = '/' + _url
    return _url
  }

  // fetchConfig();
  // const headerMenu = ref(null)
  // headerMenu.value = data.story.content.header_menu
  // console.log("fetching done")
  if (!state.started && process.client) {
    // console.log('doStart')

    let phrase = ''
    let inp_timeout: any = null
    window.addEventListener('keydown', (evt) => {
      phrase += evt.key
      if (phrase.toLowerCase() == 'debug') {
        state.app.debug = !state.app.debug
      }

      if (inp_timeout) {
        clearTimeout(inp_timeout)
      }
      inp_timeout = setTimeout(() => {
        phrase = ''
      }, 500)
    })
    state.started = true
  }
  return {
    ...toRefs(state),
    getHeaderMenu,
    fetchConfig,
    loadConfig,
    getConfig,
    getHome,
    getUrl,
    getCategoriesByID,
    getRealStory,
    handleExternalLink,
    handleInternalLink,
  }
}

// export const globalConfig = () => {

//     const { data } = getConfig()

//     return useState("global_config", () => { return data })
// }
