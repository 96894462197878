import { defineNuxtPlugin } from '#app/nuxt'
import { LazyBreadcrumbs, LazyDebugOverlay, LazyDefaultBackground, LazyDefaultFooter, LazyDefaultHeader, LazyDefaultLogo, LazyLanguageSwitcher, LazyMenuLink, LazyTheHeader, LazyEmbed, LazyEmployee, LazyEmployeeCard, LazyExtLink, LazyFooterSection, LazyGrid, LazyGroup, LazyIcon, LazyListView, LazyLogo, LazyMedia, LazyPage, LazyPageIntro, LazyPageLink, LazyPost, LazyPostPreview, LazySection, LazySoMeLink, LazyTable, LazyTextField, LazyTextFieldAnchor } from '#components'
const lazyGlobalComponents = [
  ["Breadcrumbs", LazyBreadcrumbs],
["DebugOverlay", LazyDebugOverlay],
["DefaultBackground", LazyDefaultBackground],
["DefaultFooter", LazyDefaultFooter],
["DefaultHeader", LazyDefaultHeader],
["DefaultLogo", LazyDefaultLogo],
["LanguageSwitcher", LazyLanguageSwitcher],
["MenuLink", LazyMenuLink],
["TheHeader", LazyTheHeader],
["Embed", LazyEmbed],
["Employee", LazyEmployee],
["EmployeeCard", LazyEmployeeCard],
["ExtLink", LazyExtLink],
["FooterSection", LazyFooterSection],
["Grid", LazyGrid],
["Group", LazyGroup],
["Icon", LazyIcon],
["ListView", LazyListView],
["Logo", LazyLogo],
["Media", LazyMedia],
["Page", LazyPage],
["PageIntro", LazyPageIntro],
["PageLink", LazyPageLink],
["Post", LazyPost],
["PostPreview", LazyPostPreview],
["Section", LazySection],
["SoMeLink", LazySoMeLink],
["Table", LazyTable],
["TextField", LazyTextField],
["TextFieldAnchor", LazyTextFieldAnchor],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
