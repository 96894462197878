import validate from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/validate.js";
import always_45run_45global from "/vercel/path0/middleware/always-run.global.js";
import redirect_45default_45locale_45global from "/vercel/path0/middleware/redirect-default-locale.global.ts";
import manifest_45route_45rule from "/vercel/path0/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  always_45run_45global,
  redirect_45default_45locale_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}