const state = reactive({
  loaded: false,
  story: {},
  active_story: {},
})

export function usePageManager() {
  const storyblokApi = useStoryblokApi()
   const { content_version } = useConfig()
  // const { setActivePage } =  useSettings()
  const { locale } = useI18n()
  function getBread() {
    
  }
  // function setActivePage(page:any) {

  // }
  function handleSlug(slug: any) {
    const { locale } = useI18n()
    if (slug == '') {
      return 'home'
    } else {
      if (slug.includes(locale)) slug.shift()
      return slug.join('/')
    }
  }
  async function getPage(slug: any) {
    // console.log("getPage : ", slug)

    try {
      
      slug = handleSlug(slug)
      if (slug.includes('pages/')) slug = slug.replace('pages/', '')
     
      const { data } = await storyblokApi.get(`cdn/stories/pages/${slug}`, {
        version: content_version.value,
          resolve_relations: 'link.story',
          resolve_links:'url',
        language: locale.value,
      })
      // console.log(content_version.value,data.story)
      state.active_story = state.story = data.story
      // setActiveStory(data.story)
      return data.story
    } catch (error) {
        return error
    //   console.error(error)
    }
  }
  return {
    ...toRefs(state),
    getPage,
    getBread,
    handleSlug
  }
}
