
export default function useCache() {

    let t = useState('cached-data')

    const cached_data:any = process.server && !t.value ? useState('cached-data',  () => {
        console.log("––––––––––––––––––––––––––––––––––––––––––")      
        console.log("––––––––––– Cache initialized ––––––––––––")      
        console.log("––––––––––––––––––––––––––––––––––––––––––")      
        return {links:[],loaded:false,loading:false,timer:null,first:true}
    }) : useState('cached-data')
    const updateCache = (data:object) => {
        // console.log(data)
        cached_data.value = {
            ...cached_data.value,
            ...data
        }
        //  console.log("updateCache")
        //   cached_data.value.header_menu && console.log(cached_data.value.header_menu[0])
    }
    return {cached_data,updateCache}
}